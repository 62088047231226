/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * 全局 loading
 * @description 合并多次loading请求，避免重复请求
 */

import { ElLoading } from 'element-plus'

let requestCount = 0
let instance: any

/**
 * @param target 默认 document.body; 若传入字符串，则会将其作为参数传入 document.querySelector以获取到对应 DOM 节点
 * @example 如 “main” 为主内容区域的 DOM 节点；此时 loading 会覆盖该区域
 */
const showLoading = (target?: string | HTMLElement): void => {
  if (requestCount === 0) {
    instance = ElLoading.service({ target })
  }
  requestCount++
}

const hideLoading = (): void => {
  if (requestCount < 0) return
  requestCount--
  if (requestCount === 0) {
    instance.close()
  }
}

export { showLoading, hideLoading }
