/* eslint-disable */
import { createStore, Commit } from 'vuex'
// import axios from 'axios'
import http from '@/http'
import API from '@/http/api'

export interface GlobalDataProps {
  error: GlobalErrorProps
  token: string
  columns: ColumnProps[]
  user: UserProps
}

export interface UserProps {
  isLogin: boolean
  name?: string
}

interface ImageProps {
  _id?: string
  url?: string
}

export interface GlobalErrorProps {
  status: boolean
  message?: string
}

export interface ColumnProps {
  _id: string
  title: string
  avatar?: ImageProps
  description: string
}

const getAndCommit = async (url: string, mutationName: string, commit: Commit) => {
  // const { data } = await axios.get(url)
  const data = await http({ url })
  commit(mutationName, data)
}

const postAndCommit = async (url: string, mutationName: string, commit: Commit, payload: any) => {
  // const { data } = await axios.get(url)
  const data = await http({ url, method: 'post', data: payload })
  commit(mutationName, data)
  return data
}

const store = createStore<GlobalDataProps>({
  state: {
    error: {
      status: false
    },
    token: localStorage.getItem('token') || '',
    columns: [],
    user: {
      isLogin: false
    }
  },
  mutations: {
    setError(state, e: GlobalErrorProps) {
      state.error = e
    },
    fetchColumns(state, rawData) {
      state.columns = rawData.data.list
    },
    fetchCurrentUser(state, rawData) {
      state.user = { isLogin: true, ...rawData.data }
    },
    login(state, rawData) {
      const { data } = rawData
      state.token = data
      localStorage.setItem('token', data)
    },
    logout(state) {
      state.token = ''
      state.user = { isLogin: false }
      localStorage.removeItem('token')
    }
  },
  actions: {
    fetchColumns({ commit }) {
      getAndCommit('columns', 'fetchColumns', commit)
    },
    fetchCurrentUser({ commit }) {
      return postAndCommit('/user/findCurrentUserInfo.html', 'fetchCurrentUser', commit, {})
    },
    login({ commit }, payload) {
      return postAndCommit(API.loginUrl, 'login', commit, payload)
    },
    loginAndFetch({ dispatch }, loginData) {
      return dispatch('login', loginData).then(() => {
        return dispatch('fetchCurrentUser')
      })
    }
  },
  getters: {
    getColumnById: state => (id: string) => {
      return state.columns.find(c => c._id === id)
    }
  },
  modules: {}
})

export default store
