const adminApi = process.env.VUE_APP_ADMIN_API
const uploadApi = process.env.VUE_APP_UPLOAD_API

// fastMockUrl: 'https://www.fastmock.site/mock/7fbc35e4e435a167eb56974a6ce2ee04/isameng/api/'

// interface Api {
//   loginUrl: string
//   upload: string
// }

export const otherUrl = process.env.VUE_APP_OTHER_URL

const API = {
  loginUrl: `${adminApi}/login2.html`,
  uploadUrl: `${uploadApi}/api/fileUpload/appletsUpload.html`,
  delCategoryNode: '/category/delCategoryNode.html',
  findCategoryListByTree: '/category/findCategoryListByTree.html',
  findCategoryListByTreeApi: '/api/category/findCategoryListByTree.html',
  findContentByCateId: '/category/findContentByCateId.html',
  findContentByCateIdApi: '/api/category/findContentByCateId.html',
  modifyCategoryName: '/category/modifyCategoryName.html',
  addCategoryNode: '/category/addCategoryNode.html',
  modifyContentByContentId: '/category/modifyContentByContentId.html',
  addContent: '/category/addContent.html',
  editHelpLog: '/helpLog/editHelpLog.html',
  insertHelpLog: '/helpLog/insertHelpLog.html',
  deleteHelpLog: '/helpLog/deleteHelpLog.html',
  findHelpLog: '/helpLog/findHelpLog.html',
  selectHelpLog: '/api/helpLog/selectHelpLog.html',
  findPlatformCategoryListByTree: '/platformCategory/findPlatformCategoryListByTree.html',
  customCategorySort: 'category/customCategorySort.html'
}

export default API
