import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import store from '../store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    meta: {
      title: '帮助中心管理',
      requiredLogin: true
    },
    component: () => import(/* webpackChunkName: "layout" */ '@/layouts/BasicLayout.vue'),
    children: [
      {
        path: '/',
        redirect: '/use-document'
      },
      {
        path: '/use-document',
        name: 'UseDocument',
        meta: { title: '使用说明管理' },
        component: () => import(/* webpackChunkName: "help" */ '@/views/help/UseDocument.vue')
      },
      {
        path: '/problem-document',
        name: 'ProblemDocument',
        meta: { title: '常见问题管理' },
        component: () => import(/* webpackChunkName: "help" */ '@/views/help/ProblemDocument.vue')
      },
      {
        path: '/update-document',
        name: 'UpdateDocument',
        meta: { title: '更新日志管理' },
        component: () => import(/* webpackChunkName: "help" */ '@/views/help/UpdateDocument.vue')
      }
      // {
      //   path: '/help-setting',
      //   name: 'HelpSetting',
      //   meta: { title: '设置' },
      //   component: () => import(/* webpackChunkName: "help" */ '@/views/help/HelpSetting.vue')
      // }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      redirectAlreadyLogin: true
    },
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue')
  },
  {
    path: '/help-center',
    name: 'HelpCenter',
    meta: {
      outsideLink: true
    },
    component: () => import(/* webpackChunkName: "helpCenter" */ '@/views/HelpCenter.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

const adminTitle = '汉德内容管理平台'
const helpTitle = '汉德帮助中心'

router.beforeEach((to, from, next) => {
  const { user, token } = store.state
  const { requiredLogin, redirectAlreadyLogin, outsideLink } = to.meta
  if (to.path !== from.path) {
    NProgress.start()
  }
  if (outsideLink) {
    if (document.title !== helpTitle) {
      document.title = helpTitle
    }
    next()
  } else {
    if (document.title !== adminTitle) {
      document.title = adminTitle
    }
    if (!user.isLogin) {
      if (token) {
        store.dispatch('fetchCurrentUser').then(() => {
          if (redirectAlreadyLogin) {
            next('/')
          } else {
            next()
          }
        })
        // .catch(e => {
        //   console.log(e)
        //   store.commit('logout')
        //   next('/login')
        // })
      } else {
        if (requiredLogin) {
          next('/login')
        } else {
          next()
        }
      }
    } else {
      if (redirectAlreadyLogin) {
        next('/')
      } else {
        next()
      }
    }
  }

  // if (!user.isLogin) {
  //   if (token) {
  //     if (redirectAlreadyLogin) {
  //       next('/')
  //     } else {
  //       next()
  //     }
  //   } else {
  //     if (requiredLogin) {
  //       next('/login')
  //     } else {
  //       next()
  //     }
  //   }
  // } else {
  //   if (redirectAlreadyLogin) {
  //     next('/')
  //   } else {
  //     next()
  //   }
  // }
})

router.afterEach(() => {
  NProgress.done()
})

export default router
