import axios from 'axios'
import store from '@/store'
import qs from 'qs'
import { ElMessage, ElMessageBox } from 'element-plus'
import { hideLoading } from '../utils/loading'
import API from '@/http/api'

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API
})

instance.interceptors.request.use(
  config => {
    //断网提示
    if (!navigator.onLine) {
      ElMessageBox.alert('您的网络故障，请检查!', '温馨提示', { confirmButtonText: '好的', type: 'warning' })
    }
    if (store.state.token) {
      config.headers.token = store.state.token
    }
    // config.headers.token = 'lwq202106081537'
    if (config.url === API.uploadUrl) {
      config.headers['Content-Type'] = 'multipart/form-data'
    } else {
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    }
    if (config.method === 'post' && config.url !== API.uploadUrl) {
      config.data = qs.stringify(config.data)
      // console.log(config.data)
    }
    return config
  },
  error => {
    console.log('请求拦截error', error)
    return Promise.reject(error)
  }
)

// interface TipsMessage {
//   [propName: number]: string
// }

// const tipsMessage: TipsMessage = {
//   100: '登录失败  用户名密码不对或账号被锁定',
//   101: 'TOKEN失效  Token已失效，请重新调登录接口',
//   102: '参数错误  请根据接口参数要求进行传参',
//   103: '未登录',
//   300: '接口无访问权限  没有权限访问该接口',
//   301: '数据无访问权限  没有权限访问该设备数据',
//   302: '服务到期  设备服务到期 '
// }

instance.interceptors.response.use(
  response => {
    setTimeout(() => {
      hideLoading()
    }, 200)
    // console.log(response)
    const requestUrl = response.config.url
    if (requestUrl === API.loginUrl) {
      const { message, success } = response.data
      if (success) {
        return response.data
      } else {
        ElMessage.error(message)
        return Promise.reject(message)
      }
    } else {
      const { code, message } = response.data
      if (code !== 0) {
        if (code === 101 || code === 103) {
          ElMessageBox.alert('当前用户没有登录，需要重新登录', '温馨提示', {
            confirmButtonText: '重新登录',
            type: 'warning',
            showClose: false
          }).then(() => {
            store.commit('logout')
            location.reload() // 为了重新实例化vue-router对象 避免bug
          })
        } else {
          ElMessage.error(message)
          // ElMessage.error(`${tipsMessage[code]}`)
        }
        return Promise.reject(message)
      } else {
        return response.data
      }
    }
  },
  error => {
    setTimeout(() => {
      hideLoading()
    }, 200)
    console.log('http response - err', error)
    ElMessage({
      message: '服务器异常，请稍后再试',
      type: 'error',
      duration: 5 * 1000
    })
    // store.commit('setError', { status: true, message: error.message })
    return Promise.reject(error)
  }
)

export default instance
