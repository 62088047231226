import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/style/index.scss'

// import 'element-plus/packages/theme-chalk/src/base.scss'
// import lang from 'element-plus/lib/locale/lang/zh-cn'
// import 'dayjs/locale/zh-cn'
// import locale from 'element-plus/lib/locale'

import ElementPlus from 'element-plus'
import 'dayjs/locale/zh-cn'
import locale from 'element-plus/lib/locale/lang/zh-cn'
import 'element-plus/lib/theme-chalk/index.css'

import VMdEditor from '@kangc/v-md-editor'
import '@kangc/v-md-editor/lib/style/base-editor.css'
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js'
import '@kangc/v-md-editor/lib/theme/style/github.css'
import hljs from 'highlight.js'
VMdEditor.use(githubTheme, {
  Hljs: hljs
})

import VMdPreviewHtml from '@kangc/v-md-editor/lib/preview-html'
import '@kangc/v-md-editor/lib/style/preview-html.css'
// 引入使用主题的样式
import '@kangc/v-md-editor/lib/theme/style/vuepress.css'

// ?token=306e83a2-c737-48ac-9d40-56a0bdad037e
// 内容管理平台地址后面拼接 token 实现免登录，这里先截取token存储后重新定向到无token参数的url
if (location.search) {
  const params = location.search.substr(1).split('=')
  if (params[0] === 'token') {
    localStorage.setItem('token', params[1])
    location.replace(location.origin + location.pathname)
  }
}

// import {
//   ElButton,
//   ElRow,
//   ElCol,
//   ElCard,
//   ElAvatar,
//   ElIcon,
//   ElDropdown,
//   ElDropdownMenu,
//   ElDropdownItem,
//   ElContainer,
//   ElHeader,
//   ElAside,
//   ElMain,
//   ElFooter,
//   ElMenu,
//   ElSubmenu,
//   ElMenuItem,
//   ElBreadcrumb,
//   ElBreadcrumbItem,
//   ElScrollbar,
//   ElForm,
//   ElFormItem,
//   ElInput,
//   ElSelect,
//   ElOption
// } from 'element-plus'
// const components = [
//   ElButton,
//   ElRow,
//   ElCol,
//   ElCard,
//   ElAvatar,
//   ElIcon,
//   ElDropdown,
//   ElDropdownMenu,
//   ElDropdownItem,
//   ElContainer,
//   ElHeader,
//   ElAside,
//   ElMain,
//   ElFooter,
//   ElMenu,
//   ElSubmenu,
//   ElMenuItem,
//   ElBreadcrumb,
//   ElBreadcrumbItem,
//   ElScrollbar,
//   ElForm,
//   ElFormItem,
//   ElInput,
//   ElSelect,
//   ElOption
// ]

// // 设置语言
// locale.use(lang)

const app = createApp(App)
// components.forEach(component => {
//   app.component(component.name, component)
// })
app.use(ElementPlus, { locale })
app.use(VMdEditor)
app.use(VMdPreviewHtml)
app.use(store).use(router).mount('#app')
